import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import rightArrow from '../../../assets/images/icons/right_arrow.svg';
import mailConfirm from '../../../assets/images/icon_mail_confirm.svg';
import { sendWinnerEmailReset } from '../../../core/services/hashService/sendWinnerEmail/actions';
import { SendWinnerEmailState } from '../../../core/services/hashService/sendWinnerEmail/types';
import { Constants } from '../../../core/utilities/constants';
import { history } from '../../../core/utilities/history';
import { IStore } from '../../../core/utilities/reducers';
import { router } from '../../../core/utilities/router';
import { UI } from '../../../core/utilities/ui';
import './MailConfirmModal.scss';

interface IProps {
  sendWinnerEmailState: SendWinnerEmailState;
  sendWinnerEmailReset: () => void;
}

const MailConfirmModal = (props: IProps) => {
  const isVisible = (): boolean => {
    return !!props.sendWinnerEmailState.data;
  };

  const handleMetabyteBusinessButton = () => {
    window.location.href = Constants.metaByteLink;
  }

  const handleLaterButton = () => {
    history.push(router.HOME);
    props.sendWinnerEmailReset();
  }

  return (
    <Modal
      className="mail-confirm-modal"
      maskStyle={UI.modalMaskStyle()}
      open={isVisible()}
      closable={false}
      footer={false}
      centered
      destroyOnClose
    >
      <div className="content">
        <img className="image" src={mailConfirm} alt="mail" />
        <h5 className='title'>Bilgileriniz alındı</h5>
        <p className='text'>MetaByte kampanyanızı oluşturmak için gerekli bilgiler mailinize iletilecek.</p>
        <p className='info'>Kampanyanızı bugün oluşturmak isterseniz MetaByte standına bekleriz.</p>
        <Button
          type="primary"
          className="app-button"
          onClick={handleMetabyteBusinessButton}>
          MetaByte Business <img className="ml-2" src={rightArrow} alt="arrow" />
        </Button>
        <Button
          type="link"
          className="app-link-button"
          onClick={handleLaterButton}>
          Daha sonra
        </Button>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      sendWinnerEmailReset
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    sendWinnerEmailState: store.sendWinnerEmail
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailConfirmModal);
