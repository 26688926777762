import { SEND_WINNER_EMAIL_START, SEND_WINNER_EMAIL_SUCCESS, SEND_WINNER_EMAIL_FAIL, SEND_WINNER_EMAIL_RESET } from './constants';
import { SendWinnerEmailActions, SendWinnerEmailState } from './types';

const initialState: SendWinnerEmailState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: SendWinnerEmailActions,
): SendWinnerEmailState => {
  switch (action.type) {
    case SEND_WINNER_EMAIL_START:
      return {
        ...initialState,
        loading: true,
      };
    case SEND_WINNER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SEND_WINNER_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SEND_WINNER_EMAIL_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
