import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {Errors} from '../../../utilities/errors';
import {CONSUME_HASH_START, CONSUME_HASH_SUCCESS, CONSUME_HASH_FAIL, CONSUME_HASH_RESET} from './constants';
import {ConsumeHashStartAction, ConsumeHashSuccessAction, ConsumeHashFailAction, ConsumeHashResetAction} from './types';
import {consumeHashRequest} from './repository';
import {ConsumeHashRequest} from '../../../models/requests/consumeHash.request';

const consumeHashStartAction = (): ConsumeHashStartAction => {
  return {
    type: CONSUME_HASH_START,
  };
};
const consumeHashSuccessAction = (payload: boolean): ConsumeHashSuccessAction => {
  return {
    type: CONSUME_HASH_SUCCESS,
    payload: payload,
  };
};
const consumeHashFailAction = (error: ErrorDto): ConsumeHashFailAction => {
  return {
    type: CONSUME_HASH_FAIL,
    error: error,
  };
};
const consumeHashResetAction = (): ConsumeHashResetAction => {
  return {
    type: CONSUME_HASH_RESET,
  };
};

export const consumeHash = (request: ConsumeHashRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(consumeHashStartAction());
    await consumeHashRequest(request);
    dispatch(consumeHashSuccessAction(true));
  } catch (error) {
    dispatch(consumeHashFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const consumeHashReset = () => (dispatch: Dispatch) => {
  dispatch(consumeHashResetAction());
};
