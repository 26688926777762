import { Button } from "antd";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Loading from "../../../components/Loading/Loading";
import { getHashlessTicket } from "../../../core/services/hashService/getHashlessTicket/actions";
import { GetHashlessTicketState } from "../../../core/services/hashService/getHashlessTicket/types";
import { history } from "../../../core/utilities/history";
import { IStore } from "../../../core/utilities/reducers";
import { router } from "../../../core/utilities/router";
import "./HomePage.scss";

interface IProps {
  getHashlessTicketState: GetHashlessTicketState;
  getHashlessTicket: () => void;
}

class HomePage extends Component<IProps> {
  componentDidMount() {
    this.props.getHashlessTicket();
  }

  private renderContent(): ReactNode {
    if (this.props.getHashlessTicketState.loading) {
      return <Loading fontSize={100} />;
    } else if (this.props.getHashlessTicketState.data) {
      return (
        <div className="page-content">
          <Button
            type="primary"
            className="app-button"
            onClick={() => history.push(router.WHEEL)}>
            Çevir
          </Button>
        </div>
      );
    }
    return <React.Fragment />;
  }

  render() {
    return (
      <div
        id="home-page"
        className={`page`}
      >
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getHashlessTicket,
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    getHashlessTicketState: store.getHashlessTicket,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
