import { Dispatch } from 'redux';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { HashDetailDto } from '../../../models/dtos/hashDetail.dto';
import { Errors } from '../../../utilities/errors';
import { GET_HASHLESS_TICKET_FAIL, GET_HASHLESS_TICKET_RESET, GET_HASHLESS_TICKET_START, GET_HASHLESS_TICKET_SUCCESS } from './constants';
import { getHashlessTicketRequest } from "./repository";
import { GetHashlessTicketFailAction, GetHashlessTicketResetAction, GetHashlessTicketStartAction, GetHashlessTicketSuccessAction } from './types';

const getHashlessTicketStartAction = (): GetHashlessTicketStartAction => {
  return {
    type: GET_HASHLESS_TICKET_START,
  };
};
const getHashlessTicketSuccessAction = (payload: HashDetailDto): GetHashlessTicketSuccessAction => {
  return {
    type: GET_HASHLESS_TICKET_SUCCESS,
    payload: payload,
  };
};
const getHashlessTicketFailAction = (error: ErrorDto): GetHashlessTicketFailAction => {
  return {
    type: GET_HASHLESS_TICKET_FAIL,
    error: error,
  };
};
const getHashlessTicketResetAction = (): GetHashlessTicketResetAction => {
  return {
    type: GET_HASHLESS_TICKET_RESET,
  };
};

export const getHashlessTicket = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getHashlessTicketStartAction());
    const response = await getHashlessTicketRequest();
    dispatch(getHashlessTicketSuccessAction(response));
  } catch (error) {
    dispatch(getHashlessTicketFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const getHashlessTicketReset = () => (dispatch: Dispatch) => {
  dispatch(getHashlessTicketResetAction());
};
