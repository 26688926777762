import { GET_HASHLESS_TICKET_START, GET_HASHLESS_TICKET_SUCCESS, GET_HASHLESS_TICKET_FAIL, GET_HASHLESS_TICKET_RESET } from './constants';
import { GetHashlessTicketActions, GetHashlessTicketState } from './types';

const initialState: GetHashlessTicketState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: GetHashlessTicketActions,
): GetHashlessTicketState => {
  switch (action.type) {
    case GET_HASHLESS_TICKET_START:
      return {
        ...initialState,
        loading: true,
      };
    case GET_HASHLESS_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_HASHLESS_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_HASHLESS_TICKET_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
