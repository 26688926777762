import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import error from "../../../assets/images/icon_error.svg";
import { ErrorDto } from '../../../core/models/dtos/error.dto';
import { setApiError } from '../../../core/services/appService/setApiError/actions';
import { SetApiErrorState } from '../../../core/services/appService/setApiError/types';
import { sendWinnerEmailReset } from '../../../core/services/hashService/sendWinnerEmail/actions';
import { history } from '../../../core/utilities/history';
import { IStore } from '../../../core/utilities/reducers';
import { router } from '../../../core/utilities/router';
import { UI } from '../../../core/utilities/ui';
import './ApiErrorModal.scss';

interface IProps {
  apiErrorState: SetApiErrorState;
  setApiError: (error?: ErrorDto) => void;
  sendWinnerEmailReset: () => void;
}

const ApiErrorModal = (props: IProps) => {
  const isVisible = (): boolean => {
    return !!props.apiErrorState.error;
  };

  const handleOkButton = () => {
    history.push(router.HOME);
    props.setApiError();
    props.sendWinnerEmailReset();
  }

  return (
    <Modal
      className="api-error-modal"
      maskStyle={UI.modalMaskStyle()}
      open={isVisible()}
      closable={false}
      footer={false}
      centered
      destroyOnClose
    >
      {props.apiErrorState.error &&
        <div className="content">
          <img className="icon" src={error} alt="error" />
          <h5 className='title'>Teknik bir sorunla karşılaştık.</h5>
          <p className='text'>İşleminize devam edemiyoruz. Sayfayı yenileyerek tekrar deneyebilirsiniz.</p>
          <div className='button-wrapper'>
            <Button
              type="primary"
              className="app-button"
              onClick={handleOkButton}>
              Tamam
            </Button>
          </div>
        </div>
      }
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setApiError,
      sendWinnerEmailReset
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    apiErrorState: store.setApiError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiErrorModal);
