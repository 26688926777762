import { CombinedState, combineReducers, Reducer } from 'redux';
import setApiErrorReducer from '../services/appService/setApiError/reducer';
import { SetApiErrorState } from '../services/appService/setApiError/types';
import setAppMountedReducer from '../services/appService/setAppMounted/reducer';
import { SetAppMountedState } from '../services/appService/setAppMounted/types';
import setPathnameReducer from '../services/appService/setPathname/reducer';
import { SetPathnameState } from '../services/appService/setPathname/types';
import setRemoteConfigReducer from '../services/firebaseService/setRemoteConfig/reducer';
import { SetRemoteConfigState } from '../services/firebaseService/setRemoteConfig/types';
import consumeHashReducer from '../services/hashService/consumeHash/reducer';
import { ConsumeHashState } from '../services/hashService/consumeHash/types';
import getHashlessTicketReducer from '../services/hashService/getHashlessTicket/reducer';
import { GetHashlessTicketState } from '../services/hashService/getHashlessTicket/types';
import sendWinnerEmailReducer from '../services/hashService/sendWinnerEmail/reducer';
import { SendWinnerEmailState } from '../services/hashService/sendWinnerEmail/types';

export interface IStore {
  // APP
  setApiError: SetApiErrorState;
  setAppMounted: SetAppMountedState;
  setPathname: SetPathnameState;
  // FIREBASE
  setRemoteConfig: SetRemoteConfigState;
  // HASH
  consumeHash: ConsumeHashState;
  getHashlessTicket: GetHashlessTicketState;
  sendWinnerEmail: SendWinnerEmailState;
}

export const rootReducer: Reducer<CombinedState<IStore>> = combineReducers({
  // APP
  setApiError: setApiErrorReducer,
  setAppMounted: setAppMountedReducer,
  setPathname: setPathnameReducer,
  // FIREBASE
  setRemoteConfig: setRemoteConfigReducer,
  // HASH
  consumeHash: consumeHashReducer,
  getHashlessTicket: getHashlessTicketReducer,
  sendWinnerEmail: sendWinnerEmailReducer
})
