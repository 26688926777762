import { WinnerEmailRequest } from '../../../models/requests/winnerEmail.request';
import axios from '../../../utilities/axios';

export const sendWinnerEmailRequest = async (winnerEmailRequest: WinnerEmailRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(`${process.env.REACT_APP_BASE_URL}/winner-email`,
      winnerEmailRequest
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
