import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import './App.scss';
import AppLayout from './components/Layouts/AppLayout/AppLayout';
import Loading from './components/Loading/Loading';
import ApiErrorModal from './components/Modals/ApiErrorModal/ApiErrorModal';
import HomePage from './containers/App/HomePage/HomePage';
import WheelPage from './containers/App/WheelPage/WheelPage';
import { ErrorDto } from "./core/models/dtos/error.dto";
import { setApiError } from './core/services/appService/setApiError/actions';
import { setAppMounted } from './core/services/appService/setAppMounted/actions';
import { SetAppMountedState } from './core/services/appService/setAppMounted/types';
import { setPathname } from './core/services/appService/setPathname/actions';
import { setRemoteConfig } from './core/services/firebaseService/setRemoteConfig/actions';
import { SetRemoteConfigState } from './core/services/firebaseService/setRemoteConfig/types';
import axios from './core/utilities/axios';
import { Errors } from './core/utilities/errors';
import { history } from './core/utilities/history';
import { IStore } from './core/utilities/reducers';
import { router } from './core/utilities/router';

interface IProps {
  setAppMountedState: SetAppMountedState;
  setAppMounted: (isMounted: boolean) => void;
  setRemoteConfigState: SetRemoteConfigState;
  setRemoteConfig: () => void;
  setPathname: (pathname: string) => void;
  setApiError: (error?: ErrorDto) => void;
}

interface IState {
  callbackHistoryUnregister?: VoidFunction;
}

class App extends React.Component<IProps> {
  state: IState = {
    callbackHistoryUnregister: undefined,
  };

  constructor(props: IProps) {
    super(props);
    this.setAxiosInterceptor();
    this.props.setRemoteConfig();
  }

  componentDidMount() {
    this.setHistoryListener();
    this.props.setAppMounted(true);
  }

  private setAxiosInterceptor(): void {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        this.props.setApiError(Errors.getErrorDtoFromApiError(error));
        return Promise.reject(error);
      },
    );
  }

  private setHistoryListener(): void {
    const callbackHistoryUnregister = history.listen((location: any) => {
      this.props.setPathname(location.pathname);
    });
    this.setState({ callbackHistoryUnregister });
  }

  private isAppLoading(): boolean {
    return (
      !this.props.setAppMountedState.isMounted ||
      this.props.setRemoteConfigState.loading
    );
  }

  render() {
    return (
      <Router history={history}>
        {
          this.isAppLoading()
            ?
            <Loading className="app-loading" fontSize={100} />
            :
            <AppLayout>
              <Switch>
                <Route exact path={router.HOME} component={HomePage} />
                <Route exact path={router.WHEEL} component={WheelPage} />
                <Redirect to={router.HOME} />
              </Switch>
            </AppLayout>
        }
        <ApiErrorModal />
      </Router>
    );
  }

  componentWillUnmount() {
    if (this.state.callbackHistoryUnregister) {
      this.state.callbackHistoryUnregister();
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setAppMounted,
      setRemoteConfig,
      setPathname,
      setApiError,
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setAppMountedState: store.setAppMounted,
    setRemoteConfigState: store.setRemoteConfig,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
