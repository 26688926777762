import { Dispatch } from 'redux';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { WinnerEmailRequest } from '../../../models/requests/winnerEmail.request';
import { Errors } from '../../../utilities/errors';
import { SEND_WINNER_EMAIL_FAIL, SEND_WINNER_EMAIL_RESET, SEND_WINNER_EMAIL_START, SEND_WINNER_EMAIL_SUCCESS } from './constants';
import { sendWinnerEmailRequest } from "./repository";
import { SendWinnerEmailFailAction, SendWinnerEmailResetAction, SendWinnerEmailStartAction, SendWinnerEmailSuccessAction } from './types';

const sendWinnerEmailStartAction = (): SendWinnerEmailStartAction => {
  return {
    type: SEND_WINNER_EMAIL_START,
  };
};
const sendWinnerEmailSuccessAction = (payload: boolean): SendWinnerEmailSuccessAction => {
  return {
    type: SEND_WINNER_EMAIL_SUCCESS,
    payload: payload,
  };
};
const sendWinnerEmailFailAction = (error: ErrorDto): SendWinnerEmailFailAction => {
  return {
    type: SEND_WINNER_EMAIL_FAIL,
    error: error,
  };
};
const sendWinnerEmailResetAction = (): SendWinnerEmailResetAction => {
  return {
    type: SEND_WINNER_EMAIL_RESET,
  };
};

export const sendWinnerEmail = (winnerEmailRequest: WinnerEmailRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(sendWinnerEmailStartAction());
    await sendWinnerEmailRequest(winnerEmailRequest);
    dispatch(sendWinnerEmailSuccessAction(true));
  } catch (error) {
    dispatch(sendWinnerEmailFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const sendWinnerEmailReset = () => (dispatch: Dispatch) => {
  dispatch(sendWinnerEmailResetAction());
};
