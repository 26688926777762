import { Button } from "antd";
import React, { Component, ReactNode } from "react";
import { StyleType, WheelData } from "react-custom-roulette/dist/components/Wheel/types";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import metaLogo from "../../../assets/images/meta_logo.svg";
import pointer from "../../../assets/images/wheel-pointer.svg";
import MailConfirmModal from "../../../components/Modals/MailConfirmModal/MailConfirmModal";
import PrizeMailModal from "../../../components/Modals/PrizeMailModal/PrizeMailModal";
import Wheel4p from "../../../components/Wheel4p/Wheel4p";
import { WheelInitData } from "../../../core/models/custom/wheelInitData";
import { HashDetailDto } from "../../../core/models/dtos/hashDetail.dto";
import { OfferDto } from "../../../core/models/dtos/offer.dto";
import { ConsumeHashRequest } from "../../../core/models/requests/consumeHash.request";
import { WinnerEmailRequest } from "../../../core/models/requests/winnerEmail.request";
import { consumeHash } from "../../../core/services/hashService/consumeHash/actions";
import { ConsumeHashState } from "../../../core/services/hashService/consumeHash/types";
import { GetHashlessTicketState } from "../../../core/services/hashService/getHashlessTicket/types";
import { sendWinnerEmail } from "../../../core/services/hashService/sendWinnerEmail/actions";
import { SendWinnerEmailState } from "../../../core/services/hashService/sendWinnerEmail/types";
import { Helpers } from "../../../core/utilities/helpers";
import { history } from "../../../core/utilities/history";
import { IStore } from "../../../core/utilities/reducers";
import { router } from "../../../core/utilities/router";
import "./WheelPage.scss";

interface IProps {
  getHashlessTicketState: GetHashlessTicketState;
  consumeHashState: ConsumeHashState;
  sendWinnerEmailState: SendWinnerEmailState;
  consumeHash: (request: ConsumeHashRequest) => void;
  sendWinnerEmail: (request: WinnerEmailRequest) => void;
}

interface IState {
  isWheelVisible: boolean;
  intervalId?: NodeJS.Timeout;
  mustStartSpinning: boolean;
  winnerPrize?: OfferDto;
  isPrizeMailModalVisible: boolean;
  prizeTimeoutId?: NodeJS.Timeout;
}

class WheelPage extends Component<IProps> {
  state: IState = {
    isWheelVisible: false,
    intervalId: undefined,
    mustStartSpinning: false,
    winnerPrize: undefined,
    isPrizeMailModalVisible: false,
    prizeTimeoutId: undefined
  }

  private readonly styleOdd: StyleType = { backgroundColor: '#69E391', textColor: '#191919' };
  private readonly styleEven: StyleType = { backgroundColor: '#191919', textColor: '#69E391' };
  private readonly styleAssignColor: StyleType = { backgroundColor: '#FFD700', textColor: '#191919' };

  componentDidMount() {
    if (this.props.getHashlessTicketState.data?.hash) {
      this.setIntervalWheelCreated();
    } else {
      history.push(router.HOME);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (Helpers.isFreshData(prevProps.consumeHashState, this.props.consumeHashState)) {
      if (this.props.getHashlessTicketState.data) {
        const initData = this.getWheelInitDataFromHashDetail(this.props.getHashlessTicketState.data);
        const winnerPrize: OfferDto = this.props.getHashlessTicketState.data.offers[initData.winnerIndex];
        this.dispatchWheelTurnedEvent();
        const prizeTimeoutId = setTimeout(() => {
          this.handlePrizeModalVisible(true);
        }, 2400);
        this.setState({ winnerPrize, prizeTimeoutId });
      } else {
        alert('Hash detail not defined after consume!');
      }
    }
    if (Helpers.isFreshData(prevProps.sendWinnerEmailState, this.props.sendWinnerEmailState)) {
      this.handlePrizeModalVisible(false);
    }
  }

  private dispatchWheelTurnedEvent() {
    try {
      (window as any).webkit.messageHandlers.wheelTurned.postMessage(JSON.stringify({ status: "ok" }));
    } catch (e) {
    }
    try {
      (window as any).JSBridge.wheelTurned();
    } catch (e) {
    }
  }

  private handleStart(): void {
    this.setState({ mustStartSpinning: true });
  }

  private handleFinish(): void {
    if (this.props.getHashlessTicketState.data) {
      this.props.consumeHash({ hash: this.props.getHashlessTicketState.data?.hash });
    }
  }

  private getWheelInitDataFromHashDetail(hashDetail: HashDetailDto): WheelInitData {
    const data: WheelData[] = [];
    let winnerIndex: number = 0;
    hashDetail.offers.forEach((offer, index) => {
      data.push({ option: offer.name, style: offer.attr1 === "ASSIGN_COLOUR" ? this.styleAssignColor : (index % 2 === 0 ? this.styleEven : this.styleOdd) });
      if (offer.id === hashDetail.offerId) {
        winnerIndex = index;
      }
    });
    return { data, winnerIndex };
  }

  private setIntervalWheelCreated(): void {
    const intervalId = setInterval(() => {
      const wheelPointer = document.getElementsByTagName('IMG')[0];
      if (wheelPointer) {
        this.wheelCreated(wheelPointer);
      }
    }, 1);
    this.setState({ intervalId });
  }

  private wheelCreated(wheelPointer: Element): void {
    wheelPointer.setAttribute("src", pointer);
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
      setTimeout(() => {
        this.setState({ isWheelVisible: true });
      }, 1000);
    }
  }

  private renderWheel(hashDetail: HashDetailDto): ReactNode {
    const initData = this.getWheelInitDataFromHashDetail(hashDetail);
    return (
      <div className={`wheel-wrapper ${!this.state.isWheelVisible && 'hidden'}`}>
        <Wheel4p
          isConsumeLoading={this.props.consumeHashState.loading}
          mustStartSpinning={this.state.mustStartSpinning}
          wheelData={initData.data}
          winnerIndex={initData.winnerIndex}
          callbackSpin={() => this.handleStart()}
          callbackFinished={() => this.handleFinish()}
        />
      </div>
    );
  }

  private renderHeading(): ReactNode {
    if (this.state.winnerPrize) {
      return (
        <div className="prize-title">
          {this.state.winnerPrize.description}
        </div>
      );
    }
  }

  private renderPrizeButton(): ReactNode {
    if (this.state.winnerPrize) {
      return (
        <Button
          type="primary"
          className="app-button"
          onClick={() => this.handlePrizeModalVisible(true)}>
          Devam et
        </Button>
      )
    }
    return (
      <img className="meta-logo" src={metaLogo} alt="meta" />
    );
  }

  private renderContent(): ReactNode {
    if (this.props.getHashlessTicketState.data) {
      return (
        <div className="wheel-content">
          {this.renderHeading()}
          {this.renderWheel(this.props.getHashlessTicketState.data)}
        </div>
      );
    }
    return <React.Fragment />;
  }

  private handlePrizeModalVisible(isPrizeMailModalVisible: boolean): void {
    this.setState({
      isPrizeMailModalVisible
    });
  }

  private handlePrizeModalContinue(email: string): void {
    if (this.props.getHashlessTicketState.data?.hash) {
      const winnerRequest: WinnerEmailRequest = {
        hash: this.props.getHashlessTicketState.data.hash,
        email
      };
      this.props.sendWinnerEmail(winnerRequest);
    }
  }

  render() {
    return (
      <div
        id="wheel-page"
        className={`page ${this.state.isWheelVisible && !this.state.mustStartSpinning && 'no-spinning'}`}
      >
        <div className="page-content">
          {this.renderContent()}
          {this.renderPrizeButton()}
        </div>
        <PrizeMailModal
          isVisible={this.state.isPrizeMailModalVisible}
          handleContinue={email => this.handlePrizeModalContinue(email)} />
        <MailConfirmModal />
      </div>
    );
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    if (this.state.prizeTimeoutId) {
      clearTimeout(this.state.prizeTimeoutId);
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      consumeHash,
      sendWinnerEmail
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    getHashlessTicketState: store.getHashlessTicket,
    consumeHashState: store.consumeHash,
    sendWinnerEmailState: store.sendWinnerEmail
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WheelPage);
