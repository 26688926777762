import {ConsumeHashRequest} from '../../../models/requests/consumeHash.request';
import axios from '../../../utilities/axios';

export const consumeHashRequest = async (request: ConsumeHashRequest): Promise<void> => {
  try {
    const response = await axios.patch<void>(
      `${process.env.REACT_APP_BASE_URL}/tickets/consume`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
