import { HashDetailDto } from '../../../models/dtos/hashDetail.dto';
import axios from '../../../utilities/axios';

export const getHashlessTicketRequest = async (): Promise<HashDetailDto> => {
  try {
    const response = await axios.get<HashDetailDto>(
      `${process.env.REACT_APP_BASE_URL}/hashless-ticket`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
