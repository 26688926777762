import {CONSUME_HASH_START, CONSUME_HASH_SUCCESS, CONSUME_HASH_FAIL, CONSUME_HASH_RESET} from './constants';
import {ConsumeHashActions, ConsumeHashState} from './types';

const initialState: ConsumeHashState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: ConsumeHashActions,
): ConsumeHashState => {
  switch (action.type) {
    case CONSUME_HASH_START:
      return {
        ...initialState,
        loading: true,
      };
    case CONSUME_HASH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CONSUME_HASH_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CONSUME_HASH_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
