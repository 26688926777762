import { Button } from "antd";
import { useEffect } from 'react';
import { Wheel } from 'react-custom-roulette';
import { WheelData } from "react-custom-roulette/dist/components/Wheel/types";
import button from "../../assets/images/wheel-button.svg";
import Loading from "../Loading/Loading";
import "./Wheel4p.scss";

interface IProps {
  isConsumeLoading: boolean;
  mustStartSpinning: boolean;
  wheelData: WheelData[];
  winnerIndex: number;
  callbackSpin: () => void;
  callbackFinished: () => void;
}

const Wheel4P = (props: IProps) => {
  useEffect(() => {
    const spinTimeout = setTimeout(() => {
      if (!props.mustStartSpinning) {
        handleSpin();
      }
    }, 4000);

    return () => {
      clearTimeout(spinTimeout);
    }
    // eslint-disable-next-line
  }, []);

  const handleSpin = () => {
    props.callbackSpin();
  }

  const handleStop = (): void => {
    props.callbackFinished();
  }

  return (
    <div id="wheel4p">
      <Wheel
        mustStartSpinning={props.mustStartSpinning}
        data={props.wheelData}
        prizeNumber={props.winnerIndex}
        onStopSpinning={handleStop}
        outerBorderColor="#69E391"
        outerBorderWidth={5}
        innerRadius={20}
        innerBorderColor="#191919"
        innerBorderWidth={0}
        radiusLineColor="#191919"
        radiusLineWidth={0}
        fontSize={24}
        textDistance={60}
      />
      <Button
        className="button-spin"
        disabled={props.mustStartSpinning}
        onClick={handleSpin}
      >
        {props.isConsumeLoading
          ? <Loading />
          : props.mustStartSpinning
            ? 'ÇEVİR'
            : <img className="button" src={button} alt="button" />
        }
      </Button>
    </div>
  )
}

export default Wheel4P;
