import { Button, Form, Input, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import mail from '../../../assets/images/icon_mail.svg';
import { SendWinnerEmailState } from '../../../core/services/hashService/sendWinnerEmail/types';
import { IStore } from '../../../core/utilities/reducers';
import { UI } from '../../../core/utilities/ui';
import './PrizeMailModal.scss';

interface IProps {
  isVisible: boolean;
  handleContinue: (email: string) => void;
  sendWinnerEmailState: SendWinnerEmailState;
}

const PrizeMailModal = (props: IProps) => {
  return (
    <Modal
      className="prize-mail-modal"
      maskStyle={UI.modalMaskStyle()}
      open={props.isVisible}
      closable={false}
      footer={false}
      centered
      destroyOnClose
    >
      <div className="content">
        <img className="image" src={mail} alt="mail" />
        <h5 className='title'>Hediyenizi nereye iletelim?</h5>
        <p className='text'>Eforsuz, maliyetsiz, hızlı kampanya ile hedefinize ulaşmaya bir adım kaldı.</p>
        <Form
          className='app-form'
          onFinish={values => props.handleContinue(values.email)}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Mail formatını kontrol ediniz.' }, { type: 'email', message: 'Mail formatını kontrol ediniz.' }]}
          >
            <Input placeholder='Email' />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              loading={props.sendWinnerEmailState.loading}
              type="primary"
              htmlType='submit'
              className="app-button">
              Devam et
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    sendWinnerEmailState: store.sendWinnerEmail
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrizeMailModal);
